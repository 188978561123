import React from "react"
import github from "../images/github.svg"
import "./project.css"

export const Project = props => {
  return (
    <div className="project">
      <h3 className="project-name">{props.name}</h3>
      <p className="project-purpose">{props.purpose}</p>
      <div
        className="project-background-image"
        style={{ backgroundImage: `url(${props.image})` }}
      ></div>

      <a href={props.githubLink} target="_blank" className="github-link">
        Github Link
      </a>
    </div>
  )
}
