import checktypo from "../images/checktypo.png"
import bamazon from "../images/bamazon.png"
import jstrivia from "../images/jstrivia.png"
import starwars from "../images/starwars.png"
import emotiveCounter from "../images/emotive-counter.gif"
import burger from "../images/burger.png"
import grocery from "../images/grocery.png"
import hangman from "../images/hangman.png"
import hypoinvest from "../images/hypoinvest.png"
import newsScrape from "../images/newsScrape.png"
import pascals from "../images/pascals.png"
import pictureapp from "../images/picture-app.png"
import skillswap from "../images/skillswap.png"
import train from "../images/train.png"
import tutortimes from "../images/tutortimes.png"

export const projectData = [
  {
    name: "Checktypo",
    purpose: "A CLI tool to search webpages for typos",
    description: "",
    githubLink: "https://github.com/ashishdommety/checktypo",
    hostedLink: "https://www.npmjs.com/package/checktypo",
    image: checktypo,
  },
  {
    name: "Bamazon CLI",
    purpose: "An amazon clone for the command line",
    description: "",
    githubLink: "https://github.com/ashishdommety/bamazon",
    hostedLink: "",
    image: bamazon,
  },
  {
    name: "Star Wars Game",
    purpose: "A simple star wars themed RPG game",
    description: "",
    githubLink: "https://github.com/ashishdommety/star-wars-game",
    hostedLink: "https://ashishdommety.github.io/star-wars-game/",
    image: starwars,
  },
  {
    name: "Hangman",
    purpose: "A game of hangman - built with jquery",
    description: "",
    githubLink: "https://github.com/ashishdommety/hangman-game",
    hostedLink: "https://ashishdommety.github.io/hangman-game/index.html",
    image: hangman,
  },
  {
    name: "The Emotive Counter",
    purpose: "A counter app with feelings. Built using React and Redux.",
    description: "",
    githubLink: "https://github.com/ashishdommety/emotive-counter",
    hostedLink: "",
    image: emotiveCounter,
  },
  {
    name: "Tutortimes",
    purpose:
      "An app for tutors to create quizzes. Created using React, Redux, NodeJS, and MongoDB",
    description: "",
    githubLink: "https://github.com/ashishdommety/tutor-times",
    hostedLink: "",
    image: tutortimes,
  },
  {
    name: "News Scraper",
    purpose: "Scrape the new york times for the latest stories",
    description: "",
    githubLink: "https://github.com/ashishdommety/newsScrape",
    hostedLink: "https://nytimes-scraper.herokuapp.com/",
    image: newsScrape,
  },
  {
    name: "Pascal's Triangle",
    purpose: "A Pascal's Triangle Builder, created with React and Redux Hooks.",
    description: "",
    githubLink: "https://github.com/ashishdommety/pascals-triangle",
    hostedLink: "",
    image: pascals,
  },

  {
    name: "Javascript Trivia",
    purpose: "An interactive javascript triva game",
    description: "",
    githubLink: "https://github.com/ashishdommety/TriviaGame",
    hostedLink: "https://ashishdommety.github.io/TriviaGame/",
    image: jstrivia,
  },
  {
    name: "Grocery App",
    purpose: "Angular app to list all your groceries",
    description: "",
    githubLink: "https://github.com/ashishdommety/grocery-app",
    hostedLink: "https://ashishdommety.github.io/grocery-app/",
    image: grocery,
  },
  {
    name: "HypoInvest",
    purpose: "A stock investment simulator. Built using jQuery.",
    description: "",
    githubLink: "https://github.com/ashishdommety/budgetapp",
    hostedLink: "https://project-budgetapp.github.io/budgetapp/",
    image: hypoinvest,
  },
  {
    name: "Student Skill Swap",
    purpose:
      "An app to allow college students to swap skills. Built with jQuery, Handlebars, Node JS, Express, MySQL.",
    description: "",
    githubLink: "https://github.com/ashishdommety/student-swap",
    hostedLink: "http://student-skill-swap.herokuapp.com/",
    image: skillswap,
  },
]

let projectsOnHold = [
  {
    name: "Picture App",
    purpose:
      "Search for pictures. Built with React and powered by Pixabay's API.",
    description: "",
    githubLink: "https://github.com/ashishdommety/Picture-app",
    hostedLink: "https://the-photo-app.herokuapp.com/",
    image: pictureapp,
  },
  {
    name: "Train Scheduler",
    purpose:
      "Create a train schedule - built with firebase, moment.js and jquery",
    description: "",
    githubLink: "https://github.com/ashishdommety/train-scheduler",
    hostedLink: "https://ashishdommety.github.io/train-scheduler/",
    image: train,
  },
  {
    name: "Burger App",
    purpose: "List and (virtually) eat burgers",
    description: "",
    githubLink: "https://github.com/ashishdommety/burger-app",
    hostedLink: "https://limitless-garden-72646.herokuapp.com/",
    image: burger,
  },
]
