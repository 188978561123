import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Form from "../components/form"
import { Social } from "../components/social"
import { Project } from "../components/project"
import { projectData } from "../data/projectData"
import "../styles/index.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container>
      <div className="portfolio-page">
        <h2>Projects</h2>
        <p style={{ padding: `10px` }}>
          Out of all of my projects on github, here are {projectData.length}{" "}
          that I'm happy to share. <br /> They include a node-package I wrote, a
          CLI, some front-end apps that I made for fun, and full-stack projects
          I made with friends.
        </p>

        <div className="projects">
          {projectData.map((project) => (
            <Project
              name={project.name}
              purpose={project.purpose}
              githubLink={project.githubLink}
              image={project.image}
            />
          ))}
        </div>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
