import linkedin from "../images/linkedin.svg"
import twitter from "../images/twitter.svg"
import github from "../images/github.svg"

export const socialData = [
  {
    name: "Linked-in",
    imageName: linkedin,
    link: "https://www.linkedin.com/in/ashish-dommety",
  },
  {
    name: "Github",
    imageName: github,
    link: "https://github.com/ashishdommety",
  },
  {
    name: "Twitter",
    imageName: twitter,
    link: "https://twitter.com/ash_codes",
  },
]
