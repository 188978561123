import React from "react"

import "./social.css"
import { socialData } from "../data/socialData"

export const Social = props => {
  return (
    <div className="social-div">
      <p className="social-links-title">Let's connect on social</p>
      <div className="social-links">
        {socialData.map(social => (
          <a href={social.link} target="_blank" className="social-icon">
            <img src={social.imageName} className="social-icon-image" />
          </a>
        ))}
        <div></div>
      </div>
    </div>
  )
}
